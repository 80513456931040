const navbar = {
  logo : {
    source: "images/logo.png",
    height : 45,
    width : 50
  },
  sections: [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "About",
      href: "/about"
    },
    {
      title: "Skills",
      href: "/skills"
    },
    {
      title: "Education",
      href: "/education"
    },
    {
      title: "Experience",
      href: "/experience"
    },
    {
      title: "Projects",
      href: "/projects"
    },
    {
      title: "Resume",
      href: "/resume",
      type: "link"
    }
  ]
};

export default navbar;
