import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import FallbackSpinner from './components/FallbackSpinner';
import NotFound from "./components/NotFound";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import ExperienceList from "./pages/Experience/ExperienceList";
import EducationList from "./pages/Education/EducationList";
import HomePage from "./pages/Home/HomePage";
import AboutPage from "./pages/About/AboutPage";

function MainApp() {
  return (
      <main className="main">
          <Suspense fallback={<FallbackSpinner />}>
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/about" element={<AboutPage header='About'/>} />
                  <Route path="/skills" element={<Skills header='Skills'/>} />
                  <Route path="/education" element={<EducationList header='Education'/>} />
                  <Route path="/experience" element={<ExperienceList header='Experience'/>} />
                  <Route path="/projects" element={<Projects header='Projects'/>} />
                  <Route path="*" element={<NotFound />} />
              </Routes>
          </Suspense>
      </main>
  );
}

export default MainApp;


